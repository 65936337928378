import Link from 'next/link';
import Image from 'next/image';
import format from '@/utils/format';
import { DATETIME } from '@/constants/dateTime';
import { IPostCardProps } from '@/interfaces/card';

interface IHorizontalPostCardProps extends IPostCardProps {
  isOnTopSection?: boolean;
}

const HorizontalPostCard = ({
  id,
  category,
  // description,
  publishedAt,
  slug,
  thumbnail,
  title,
  isOnTopSection,
}: IHorizontalPostCardProps) => {
  return (
    <>
      {id ? (
        <Link
          style={{
            animation: 'animate-zoomin 0.5s',
          }}
          href={`/${category?.slug}/${slug}-${id}`}
          className={`group`}
        >
          <div className={`gap-2 lg:gap-[22px] flex`}>
            <div
              className={`${
                !isOnTopSection
                  ? 'min-w-[100px] h-[72px]'
                  : 'min-w-[90px] h-[60px]'
              } relative overflow-hidden`}
            >
              <Image
                fill
                src={thumbnail || ''}
                alt={title || ''}
                quality={70}
                style={{ objectFit: 'cover' }}
                sizes="(max-width: 768px) 50vw, (max-width: 1200px) 20vw, 10vw"
              />
            </div>

            <div
              className={`flex flex-col justify-between ${
                !isOnTopSection ? 'pb-2' : ''
              }`}
            >
              <h2
                className={`${
                  !isOnTopSection ? 'font-bold text-base' : 'text-sm'
                }  font-medium line-clamp-2 break-words group-hover:text-primary`}
              >
                {title}
              </h2>

              <p className={`text-xs font-normal text-DarkBlue`}>
                {format.dateTime(publishedAt || '', DATETIME.FUll)}
              </p>
            </div>
          </div>
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

export default HorizontalPostCard;
